import React from "react";
import STheme, { useSTheme } from "./theme";
import { css, Global } from "@emotion/core";

interface Props {}

const SGlobalStyles: React.FC<Props> = (props: Props) => {
  const theme = useSTheme();
  return (
    <Global
      styles={css`
        body {
          margin: 0;
          padding: 0;
          font-family: ${STheme.fonts.default};
          background-color: ${STheme.colors.white};
          color: ${STheme.colors.black};
          box-sizing: content-box;
          overscroll-behavior: none;
        }

        .ReactModal__Body--open {
          overflow-y: hidden;
        }

        a {
          color: #fefefe;
          text-decoration: none;
          cursor: pointer;
        }

        document #__next {
          min-height: 100vh;
        }

        .react-datepicker-wrapper {
          width: 100%;
        }

        .react-datepicker__triangle {
          left: 20px !important;
        }

        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--selected,
        .react-datepicker__year-text--in-selecting-range,
        .react-datepicker__year-text--in-range,
        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected,
        .react-datepicker__year-text--keyboard-selected {
          background-color: ${STheme.colors.purple100};
          color: ${STheme.colors.purple300};
        }

        .ReactModal__Content {
          top: 50%;
          left: 50%;
          right: auto;
          bottom: auto;
          margin-right: -50%;
          transform: translate(-50%, -50%);
          padding: 0;
          border: 0;
          background-color: ${theme.colors.white};
          box-shadow: ${theme.shadows.modal};
          border-radius: ${theme.radii[3]}px;
          @media screen and (max-width: ${theme.breakpoints[0]}) {
            width: 90%;
            border-radius: 0px;
            height: auto;
            padding: 0 20px;
            min-height: auto;
            max-height: 100%;
            overflow-y: scroll;
          }
          position: absolute;
          border: none;
          outline: none;
        }

        .ReactModalPortal {
          width: 100vh;
          height: 100vh;
          position: absolute;
          top: 0;
        }

        .ReactModal__Overlay {
          z-index: 1000;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.4);
          /* background-color: white; */
          position: fixed;
          inset: 0px;
        }

        .rnc__notification-title {
          font-size: 18px;
        }
        .rnc__notification-message {
          font-size: 18px;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }
      `}
    />
  );
};

export default SGlobalStyles;
