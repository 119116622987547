import React, { useState } from "react";
import { ThemeProvider } from "emotion-theming";
import type { AppProps } from "next/app";
import STheme, { SStyled } from "styles/theme";

import { ApolloProvider } from "@apollo/client";
import { useApollo } from "utils/apolloClient";

import Head from "next/head";

import "animate.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";

import { AuthContextProvider } from "utils/authContext";

import Fonts from "utils/fonts";
import Modal from "react-modal";

import * as Sentry from "@sentry/node";

import getConfig from "next/config";
import { appWithTranslation } from "i18n";

import SFlex from "components/opinionless/SFlex";
import SGlobalStyles from "styles/globals";
import SIdleTimer from "components/opinionless/SIdleTimer";
import { useRouter } from "next/router";
import { useTrackRouteChanges } from "../utils/gtag";
import { PlausibleProviderWrapper } from "utils/plausible";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const GlobalWrapper = SStyled(SFlex)`
    flex-direction: row;
    min-height: 100vh;
    position: relative;
    overflow-y: scroll;
`;

function MyApp(props: AppProps & { props: { [key: string]: string } }) {
  const { Component, pageProps, props: appProps } = props;
  const apolloClient = useApollo(pageProps.initialApolloState);
  const [hasLoadedFonts, setHasLoadedFonts] = useState(false);
  const router = useRouter();

  useTrackRouteChanges(router);

  React.useEffect(() => {
    Fonts()
      .catch(() => {
        setHasLoadedFonts(true);
      })
      .finally(() => {
        setHasLoadedFonts(true);
      });
  }, []);

  Modal.setAppElement("#__next");

  return (
    <ApolloProvider client={apolloClient}>
      <AuthContextProvider>
        <ThemeProvider theme={STheme}>
          <Head>
            <title>Court Reminders</title>
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/d7dkctsr';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
              }}
            />
            <link
                rel="icon"
                href='/favicon.ico'
            />
            <meta
              name="viewport"
              id="viewport"
              content="width=device-width,minimum-scale=1.0,maximum-scale=1.0,initial-scale=1.0"
            ></meta>
            <meta name="description" content="Free text reminders for LA County court hearings"></meta>
            <meta name="keywords" content="court reminders, court hearing, los angeles, text message reminders, sms, court, fta, failure to appear, court support"></meta>
          </Head>
          {hasLoadedFonts && (
            <GlobalWrapper>
              <SIdleTimer />
              <PlausibleProviderWrapper>
                <Component {...pageProps} />
              </PlausibleProviderWrapper>
            </GlobalWrapper>
          )}
          <SGlobalStyles />
        </ThemeProvider>
      </AuthContextProvider>
    </ApolloProvider>
  );
}

export default appWithTranslation(MyApp);
