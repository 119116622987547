import { ApolloClient, createHttpLink, fromPromise, Observable } from '@apollo/client'

import { onError } from '@apollo/client/link/error'

import cache from './cache'
import { LocalStorageKeys } from '../localStorage'
// import { RefreshTokenDocument } from '../../types/graphql'
import Router from 'next/router'

let isRefreshing = false
let pendingRequests: any[] = []

export const UNAUTHED_MESSAGE = 'You do not have permission to perform this action'

const setIsRefreshing = (value: boolean) => {
    isRefreshing = value
}

const addPendingRequest = (pendingRequest: any) => {
    pendingRequests.push(pendingRequest)
}

const renewTokenApiClient = new ApolloClient({
    link: createHttpLink({ uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT }),
    cache,
    credentials: 'include',
})

const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback())
    pendingRequests = []
}

// const getNewToken = async () => {
//     const oldRefreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN_KEY)

//     const {
//         data: {
//             refreshToken: { refreshToken, token, success },
//         },
//     } = await renewTokenApiClient.mutate({
//         // mutation: RefreshTokenDocument,
//         variables: { refreshToken: oldRefreshToken },
//     })

//     if (success) {
//         localStorage.setItem(LocalStorageKeys.TOKEN_KEY, token)
//         localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN_KEY, refreshToken)

//         return {
//             refreshToken,
//             token,
//         }
//     } else {
//         throw new Error('Refresh Token Failed')
//     }
// }

const apolloClientErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            switch (err?.message) {
                case UNAUTHED_MESSAGE:
                    if (!isRefreshing) {
                        setIsRefreshing(true)

                        // return fromPromise(
                        //     getNewToken().catch(() => {
                        //         resolvePendingRequests()
                        //         setIsRefreshing(false)

                        //         //
                        //         if (localStorage.getItem(LocalStorageKeys.TOKEN_KEY)) {
                        //             localStorage.removeItem(LocalStorageKeys.TOKEN_KEY)
                        //             localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN_KEY)

                        //             // Cache shared with main client instance
                        //             renewTokenApiClient.cache.reset()

                        //             Router.replace('/login')
                        //         }

                        //         return new Observable((subscriber) => {
                        //             subscriber.error('BLA')
                        //         })
                        //     })
                        // ).flatMap(() => {
                        //     resolvePendingRequests()
                        //     setIsRefreshing(false)

                        //     return forward(operation)
                        // })
                    } else {
                        // return fromPromise(
                        //     new Promise((resolve) => {
                        //         addPendingRequest(() => resolve())
                        //     })
                        // ).flatMap(() => {
                        //     return forward(operation)
                        // })
                    }
                default:
                    console.log(
                        `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
                    )
            }
        }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`)
})

export default apolloClientErrorLink
