import { ApolloClient, ApolloLink, gql, makeVar } from '@apollo/client'

import { setContext } from '@apollo/client/link/context'

import { createUploadLink } from 'apollo-upload-client'

import { LocalStorageKeys } from '../localStorage'
import { useMemo } from 'react'

import apolloClientCache from './cache'
import apolloClientErrorLink from './errorLink'

let apolloClient: ApolloClient<any> | null = null

// a lot of the refresh code is from here:
// https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8

const isRefreshing = false
let pendingRequests = []

export const UNAUTHED_MESSAGE = 'You do not have permission to perform this action'

const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback())
    pendingRequests = []
}

// const REFRESH_TOKEN = gql`
//     mutation RefreshToken($refreshToken: String!) {
//         refreshToken(refreshToken: $refreshToken) {
//             token
//             payload
//             success
//             errors
//             refreshToken
//         }
//     }
// `

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = process.browser ? localStorage.getItem(LocalStorageKeys.TOKEN_KEY) : undefined

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            ...(token && {
                authorization: `JWT ${token}`,
            }),
        },
    }
})

const logger = new ApolloLink((operation, forward) => {
    console.time(`[apolloClient] ${operation.operationName}`)
    return forward(operation).map((result) => {
        console.timeEnd(`[apolloClient] ${operation.operationName}`)
        return result
    })
})

export const localStateVar = makeVar<{
    currentModal: null
}>({
    currentModal: null,
})

const filterKeyArgsToArgs = (keyArgs: any) => (args: any) =>
    // eslint-disable-next-line no-prototype-builtins
    args ? keyArgs.filter((keyArg: any) => args.hasOwnProperty(keyArg)) : null

const createApolloClient = () => {
    return new ApolloClient({
        uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
        connectToDevTools: process.env.NODE_ENV !== 'production',
        link: ApolloLink.from([
            apolloClientErrorLink,
            authLink,
            logger,
            // @ts-ignore
            createUploadLink({
                uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
                credentials: 'include',
            }),
        ]),
        cache: apolloClientCache,
        ssrMode: !process.browser,
    })
}

export const initializeApollo = (initialState = null) => {
    const _apolloClient = apolloClient ?? createApolloClient()

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract()
        // Restore the cache using the data passed from getStaticProps/getServerSideProps
        // combined with the existing cached data
        _apolloClient.cache.restore({ ...existingCache, ...initialState })
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient

    return _apolloClient
}

export const useApollo = (initialState) => {
    const store = useMemo(() => initializeApollo(initialState), [initialState])
    return store
}
