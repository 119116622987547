import moment from 'moment'

export const padHHMMSSNumber = (n: number): string => {
    return (n < 10 ? '0' : '') + n
}

export const secondsToSmartHHMMSS = (totalSeconds: number | null): string => {
    if (totalSeconds === null) return null
    const duration = moment.duration(totalSeconds, 'seconds')
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()

    if (hours) {
        return `${hours}:${padHHMMSSNumber(minutes)}:${padHHMMSSNumber(seconds)}`
    } else {
        return `${minutes}:${padHHMMSSNumber(seconds)}`
    }
}
