import { InMemoryCache } from '@apollo/client'

const apolloClientCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {},
        },
    },
})

export default apolloClientCache
