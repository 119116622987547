import FontFaceObserver from 'fontfaceobserver'

const FONT_FAMILY = 'IBM Plex Sans' // 'INSERT_FONT_HERE'

const Fonts = () => {
    if (!FONT_FAMILY) {
        return new Promise((resolve) => resolve(true))
    }

    const link = document.createElement('link')
    link.href = 'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;600;700&family=IBM+Plex+Mono:wght@200;300;400;600;700&display=swap'
    link.rel = 'stylesheet'

    document.head.appendChild(link)

    const ffo = new FontFaceObserver(FONT_FAMILY)

    return ffo.load()
}

export default Fonts
