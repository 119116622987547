import React from "react";
import PlausibleProvider from "next-plausible";

type Props = { children?: React.ReactNode };

export const PlausibleProviderWrapper: React.FC<Props> = (props: Props) => {
  const webAppUrl =
    typeof window !== "undefined" ? window.location.hostname : "";
  if (typeof window !== "undefined" && webAppUrl) {
    return (
      <PlausibleProvider
        enabled={!!webAppUrl}
        domain={webAppUrl}
        trackOutboundLinks={true}
      >
        {props.children}
      </PlausibleProvider>
    );
  }

  return <>{props.children}</>;
};
