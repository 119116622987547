import React, { useCallback, useContext, useEffect, useState } from 'react'
import { BoxProps } from 'rebass'

import { useIdleTimer } from 'react-idle-timer'

import Modal from 'react-modal'
import { SStyled } from '../../styles/theme'
import SText from './SText'
import SBox from './SBox'
import { useTranslation } from '../../i18n'
import SFlex from 'components/opinionless/SFlex'
import { AuthContext, LogoutReason } from 'utils/authContext'
import moment, { Moment } from 'moment'
import SButton, { SButtonType } from './SButton'

import { useTimer } from 'react-timer-hook'
import { padHHMMSSNumber } from '../../utils/date'

type Props = BoxProps

const ModalTitle = SStyled(SText)`
    font-size: 24px;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    margin-bottom: ${(p) => p.theme.space[6]}px;
`

const ModalMessage = SStyled(SText)`
    font-size: ${(p) => p.theme.fontSizes[4]}px;
    color: ${(p) => p.theme.colors.gray400};
    margin-bottom: ${(p) => p.theme.space[7]}px;
    white-space: pre-wrap;
`

const ContentWrapper = SStyled(SBox)`
    width: 500px;
    @media screen and (max-width: ${(p) => p.theme.breakpoints[0]}) {
        width: 100%;
    }
`

const VISIBLE_TAB_SHOW_MODAL_NUM_SECONDS = 60 * 15
const VISIBLE_TAB_LOGOUT_NUM_SECONDS = 60
const HIDDEN_TAB_LOGOUT_NUM_SECONDS = 60 * 15
// const VISIBLE_TAB_SHOW_MODAL_NUM_SECONDS = 15
// const VISIBLE_TAB_LOGOUT_NUM_SECONDS = 15
// const HIDDEN_TAB_LOGOUT_NUM_SECONDS = 15

const SIdleTimer: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation()

    const [idle, setIdle] = useState(false)
    const [lastVisibilityTime, setLastVisibilityTime] = useState<Moment | null>(null)

    const { user, logout } = useContext(AuthContext)

    const onShouldLogout = useCallback(() => {
        // resetIdleTimer()
        // setIdle(false)
        // logout(LogoutReason.Inactivity)
    }, [])

    const { seconds: logoutTimerSeconds, minutes: logoutTimerMinutes, restart: restartLogoutTimer } = useTimer({
        expiryTimestamp: Date.now(),
        onExpire: () => {
            if (idle) {
                onShouldLogout()
            }
        },
    })

    const handleOnIdle = useCallback(() => {
        if (!idle && user) {
            const time = new Date()
            time.setSeconds(time.getSeconds() + VISIBLE_TAB_LOGOUT_NUM_SECONDS)
            setIdle(true)
            restartLogoutTimer(time.getTime())
        }
    }, [user, setIdle, restartLogoutTimer])

    const { reset: resetIdleTimer, pause: pauseIdleTimer } = useIdleTimer({
        timeout: 1000 * VISIBLE_TAB_SHOW_MODAL_NUM_SECONDS,
        onIdle: handleOnIdle,
        debounce: 500,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
        ],
    })

    const onContinueSession = useCallback(() => {
        resetIdleTimer()
        setIdle(false)
    }, [resetIdleTimer, setIdle])

    const onVisibilityChange = useCallback(
        (e: any) => {
            const visibilityState = e.target.visibilityState
            if (visibilityState === 'hidden') {
                if (!idle) {
                    setLastVisibilityTime(moment())
                }
            } else {
                if (
                    lastVisibilityTime &&
                    user &&
                    moment().diff(lastVisibilityTime, 'seconds') > HIDDEN_TAB_LOGOUT_NUM_SECONDS
                ) {
                    onShouldLogout()
                }
            }
        },
        [lastVisibilityTime, setLastVisibilityTime, pauseIdleTimer, user, setIdle, onShouldLogout]
    )

    useEffect(() => {
        document.addEventListener('visibilitychange', onVisibilityChange)
        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange)
        }
    }, [onVisibilityChange])

    if (!idle) {
        return null
    }

    return (
        <Modal isOpen={idle} overlayClassName={'remove-style'} className={'remove-style'}>
            <ContentWrapper px={[7, 9, 9]} py={[7, 8, 8]}>
                <ModalTitle>{t('common:idle_modal_title')}</ModalTitle>
                <ModalMessage>{t('common:idle_modal_message')}</ModalMessage>
                <SText fontSize="24px" fontWeight="bold" mb={7}>
                    {padHHMMSSNumber(logoutTimerMinutes)}:{padHHMMSSNumber(logoutTimerSeconds)}
                </SText>

                <SFlex flexDirection="row">
                    <SButton onClick={onContinueSession} buttonType={SButtonType.Outline} mr={5}>
                        {t('common:idle_modal_continue_session')}
                    </SButton>
                    <SButton onClick={onShouldLogout} buttonType={SButtonType.Cancel}>
                        {t('common:idle_modal_sign_out')}
                    </SButton>
                </SFlex>
            </ContentWrapper>
        </Modal>
    )
}

export default SIdleTimer
